<template>
  <div class="policy-background">
    <div class="content-div" style="height: 776px;overflow-y: scroll;">
      <!-- <iframe src="/static/zcbj.html" width="100%" height="100%" frameborder="0"></iframe> -->
      <h1 class="title-name">{{titleName}}</h1>
      <div class="content-content" v-html="content" style="width:98%;height:100%;line-height: 32px;">{{content}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Qindan",
  data() {
    return {
      titleName: "智能工厂路线图",
      content: `<p align="center">[匠心智造、发现之旅]</p>
<p align="center">长三角智能制造数字化之旅-上海站</p>
<p>&nbsp;</p>
<p align="center"><img src='${require('../assets/imgs/lvyou-1.png')}'></p>
<p align="center">（汽车之旅）</p>
<p>&nbsp;</p>
<p align="center"><img src='${require('../assets/imgs/lvyou-2.png')}'></p>
<p align="center">（电子信息之旅）</p>
<p>&nbsp;</p>
<p align="center"><img src='${require('../assets/imgs/lvyou-3.png')}'></p>
<p align="center">（高端装备之旅）</p>
<p>&nbsp;</p>
<p align="center"><img src='${require('../assets/imgs/lvyou-4.png')}'></p>
<p align="center">（生命健康之旅）</p>
<p>&nbsp;</p>
<p align="center"><img src='${require('../assets/imgs/lvyou-5.png')}'></p>
<p align="center">（先进材料之旅）</p>
<p>&nbsp;</p>
<p align="center"><img src='${require('../assets/imgs/lvyou-6.png')}'></p>
<p align="center">（时尚消费品之旅）</p>`
    };
  },
  props: {
    msg: String
  },
  created() {
    this.getData(123);
  },
  methods: {
    getData(data) {}
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.title-name{
    color: white;
}
.content-content {
  color: white;
  text-align: left;
  img {
      width: 500px!important;
  }
}
.policy-background {
  width: 1440px;
  height: 796px;
  background: #000066;
  margin-top: 132px;
  padding: 10px;
  .content-div {
    width: 1420px;
    height: 776px;
    margin: 0 10px;
    // overflow: scroll;
    // @thumb_scroll();
  }
}
</style>
